import React, { useState } from "react";
import Accent from "../../images/WeddingDetails/accent.jpeg";
import lineBreak from "../../images/WeddingDetails/lineBreak.png";
import { MdClose } from "react-icons/md";
import './WeddingDetails.css';

export default function WeddingDetails() {

  // State Variables for controlling modals
  const [isSpecialOfferModalOpen, setIsSpecialOfferModalOpen] = useState(false);
  const [isHoneymoonFundModalOpen, setIsHoneymoonFundModalOpen] = useState(false);

  // Functions to open modals
  const handleSpecialOfferModalOpen = () => {
    setIsSpecialOfferModalOpen(true);
  };

  const handleHoneymoonFundModalOpen = () => {
    setIsHoneymoonFundModalOpen(true);
  };

  // Functions to close modals
  const handleSpecialOfferModalClose = () => {
    setIsSpecialOfferModalOpen(false);
  };

  const handleHoneymoonFundModalClose = () => {
    setIsHoneymoonFundModalOpen(false);
  };



  return (
    <div className='WeddingDetails' id="WeddingDetails">
      <div id='detailsHeading'>
        <h1 id='detailsHeadingOne'>Troue</h1>
        <h1 id='detailsHeadingTwo'>Besonderhede</h1>
      </div>
      <img src={Accent} className="accent" alt='' />
      <h2>Naweek Verigtinge</h2>
      <div id="weddingSchedule">
        <div id="dayBefore">
          <br />
          <h4>DIE DAG DAARVOOR</h4>
          <p>Sluit asseblief by ons aan vir 'n</p>
          <p>ONTMOET EN GROET</p>
          <p>Vrydag, 22 November 2024</p>
          <p>16:30 to 19:30</p>
          <p>'Cape to Cuba',</p>
          <p>165 Main Road, Kalkbaai</p>
          <br />
        </div>
        <img src={lineBreak} id="lineBreak" alt="" />
        <div>
          <h4>DIE TROUE</h4>
          <p>Saterdag, 23 November 2024</p>
          <div >
            <p>14:45 - Alle gaste neem hul sitplekke in vir die seremonie.</p>
            <p>15:00 - Seremonie</p>
          </div>
          <p>Casa Labia deur Ideas Cartel</p>
          <p>Onthaal sal volg na seremonie.</p>
        </div>
      </div>
      <img src={Accent} className="cashbarAccent" alt='' />
      <p id="cashbar">Daar sal 'n kontantkroeg beskikbaar wees Vrydag- en Saterdagaand.</p>
      <img src={Accent} className="cashbarAccent" alt='' />
      <br />
      <br />
      <h2>Kleredrag</h2>
      <br />
      <div id="dressCode">
        <div id="men">
          <p className="gender">MANS</p>
          <p>
            Pak en Das
            <br />
            - OF -
            <br />
            Tuxedo
          </p>
          <p>
            <br />
            MOENIES
            <MdClose id="dontCross" />
          </p>
          <div className="colours">
            <div className="colour colourOne"></div>
            <div className="colour colourTwo"></div>
            <div className="colour colourThree"></div>
            <div className="colour colourFour"></div>
            <div className="colour colourFive"></div>
          </div>
        </div>
        <img src={lineBreak} id="lineBreak" alt="" />
        <div id="women">
          <p className="gender">DAMES</p>
          <p>
            Formele Klere
            <br />
            - OF -
            <br />
            Aandrok
          </p>
          <p>
            <br />
            MOENIES
            <MdClose id="dontCross" />
          </p>
          <div className="colours">
            <div className="colour colourSix"></div>
            <div className="colour colourSeven"></div>
            <div className="colour colourEight"></div>
            <div className="colour colourNine"></div>
            <div className="colour colourTen"></div>
          </div>
        </div>
      </div>
      <p id="disclaimer">
        *MOENIES beteken enige kleure
        <strong> BEHALWE</strong> hierdie
      </p>
      <button id="specialOffer" onClick={handleSpecialOfferModalOpen}>15% af op Rokke van 'StyleRotate'</button>
      <img src={Accent} className="accent" alt='' />
      <p id="dressCodeDescription">
        Let wel,
        die onthaal sal buite wees en dit kan saans bietjie koud raak aan die kus.
        <br />
        - Bring gerus 'n serp of ligte baadjie saam.
      </p>
      <img src={Accent} className="accent" alt='' />

      {/* Modal */}
      {isSpecialOfferModalOpen && (
        <div className="offerModal">
          <div className="offerModal-content">
            <span className="offerClose" onClick={handleSpecialOfferModalClose}>&times;</span>
            <p>Ons het 'n 15%-afslagkode by StyleRotate bekom vir die huur van aandrokke:</p>
            <p><strong>FOREVER4E</strong></p>
            <p>Die kode is beskikbaar vanaf 1 September tot en met middernag 24 November.</p>
            <p id="disclaimer">*Let asseblief daarop dat jy slegs drie maande vooruit kan bespreek.</p>
            <a href="https://stylerotate.com/" target="_blank" rel="noreferrer" className="offerModalButton">Besoek StyleRotate Webblad</a>
          </div>
        </div>
      )}

      <section id="gifts">
        <h1>Geskenke</h1>
        <br />
        <p>Julle teenwoordigheid by ons troue is die beste geskenk wat ons kan ontvang. Indien enigiemand egter 'n bydrae wil maak, sal ons 'n kontantskenking tot ons wittebroodsfonds baie waardeer, sodat ons werklik 'n onvergeetlike ervaring daarvan kan maak.</p>
        <button id="honeymoonFund" onClick={handleHoneymoonFundModalOpen}>Wittebroodfonds</button>
      </section>

      {/* Honeymoon Fund Modal */}
      {isHoneymoonFundModalOpen && (
        <div className="honeymoonModal">
          <div className="honeymoonModal-content">
            <span className="honeymoonClose" onClick={handleHoneymoonFundModalClose}>&times;</span>
            <p>Om te skenk, gebruik asseblief die volgende bankbesonderhede:</p>
            <p className="bankingDetails">A.Strydom
              <br />ABSA
              <br />Account Type: Current
              <br />Account number: 4098298522
              <br />Branch Code: 632005
              <br />Reference: Honeymoon Fund
            </p>
            <p>Roomys - R50
              <br />Padkos - R100
              <br />Kaasproe- R150
              <br />Bottel Wyn - R250
              <br />Cocktails - R300
              <br />Pizza - R450
              <br />Wynproe - R500
              <br />Piekniek - R650
              <br />Couples Massage - R800
              <br />Quad Bike - R1000
              <br />Tank Petrol- R1200
            </p>
          </div>
        </div>
      )}
    </div>
  );
} 