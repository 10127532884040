import VenuePhotoOne from "../../images/AboutTheVenue/VenueOne.png";
import VenuePhotoTwo from "../../images/AboutTheVenue/VenueTwo.png";
import VenuePhotoThree from "../../images/AboutTheVenue/VenueThree.png";
import CasaLabiaLogo from "../../images/AboutTheVenue/CasaLabiaLogo.png";
import './AboutTheVenue.css';

export default function AboutTheVenue() {
  return (
    <div className='AboutTheVenue' id="AboutTheVenue">
      <img src={VenuePhotoOne} className="VenuePhoto" alt="" />
      <img src={VenuePhotoTwo} className="VenuePhoto" alt="" />
      <img src={VenuePhotoThree} className="VenuePhoto" alt="" />
      <div className="heading">
        <h1 id="headingLeft">Oor</h1>
        <h1>Die</h1>
        <h1 id="headingRight">Venue</h1>
      </div>
      <a href="https://www.casalabia.com/" target="_blank" rel="noreferrer">
        <img src={CasaLabiaLogo} id="venueLogo" alt="Casa Labia Logo" />
      </a>
      <p>Casa Labia, geleë in die hoofstraat van Muizenberg, kombineer die prag en praal van 'n vervloë era met moderne funksionaliteit!
      </p>
      <br />
      <p>
        Hierdie nasionale monument, oorspronklik die Italiaanse ambassade, beskik oor 'n indrukwekkende ingevoerde Venesiese interieur en bied 'n unieke kombinasie van geskiedenis en elegansie - ideaal vir onvergeetlike trouherinneringe.
      </p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.3730317367267!2d18.463844576065323!3d-34.11119863165525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc41ae596d63db%3A0x24cbd92fd9f19c55!2sCasa%20Labia!5e0!3m2!1sen!2sza!4v1719212082769!5m2!1sen!2sza"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title='sonnekus'
      />
    </div>
  );
}