import AccentInvert from "../../images/Invite/AccentInvert.png";
import './Invite.css';

export default function Invite() {
  return (
    <div className='Invite'>
      <img src={AccentInvert} className="inviteAccent" alt="" />
      <p className="inviteBody">Adolph and Naomi Strydom request the honour of your presence at the marriage of their daughter</p>
      <p className="inviteName antoinette">Antoinette Strydom</p>
      <p id="to">TO</p>
      <p className="inviteName hanro">Hanro Fourie</p>
      <p className="inviteBody">son of Wietsche and Elaine Fourie.</p>
      <img src={AccentInvert} className="inviteAccent" alt="" />
    </div>
  );
} 