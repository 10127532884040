import React from 'react';
import Countdown from 'react-countdown';
import { IoChevronDownCircle } from "react-icons/io5";
import './Landing.css';

export default function Landing() {
  // Date for countdown
  const targetDate = new Date('2024-11-23T12:00:00');

  // Renderer function to customize the display
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a if countdown is completed
      return <span>Time's up!</span>;
    } else {
      // Render the countdown
      return (
        <div className='countdown fade-in'>
          <div className='days-container countdown-item'>
            <span className='countdown-number'>{days}</span>
            <span className='counter-unit'>days</span>
          </div>
          <div className='hours-container countdown-item'>
            <span className='countdown-number'>{hours}</span>
            <span className='counter-unit'>hours</span>
          </div>
          <div className='minutes-container countdown-item'>
            <span className='countdown-number'>{minutes}</span>
            <span className='counter-unit'>minutes</span>
          </div>
          <div className='seconds-container countdown-item'>
            <span className='countdown-number'>{seconds}</span>
            <span className='counter-unit'>seconds</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className='Landing'>
      <h1 className='Landing-Header fade-in'>Antoinette & Hanro</h1>
      <div>
        <p className='date fade-in'>23 November 2024</p>
        <Countdown date={targetDate} renderer={renderer} className='fade-in' />
      </div>
      <a href='#RSVP' className='RSVP-button fade-in'>RSVP by 1 September</a>
      <div id='scrollDown'>
        <p id='scrollDownText'>Scroll Down</p>
        <IoChevronDownCircle id='scrollIcon' />
      </div>
      <div />
    </div>
  );
} 