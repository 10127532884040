import AccentInvert from "../../images/Invite/AccentInvert.png";
import './Invite.css';

export default function Invite() {
  return (
    <div className='Invite'>
      <img src={AccentInvert} className="inviteAccent" alt="" />
      <p className="inviteBody">Adolph en Naomi Strydom nooi u hartlik uit na die huweliksbevestiging van hul dogter</p>
      <p className="inviteName antoinette">Antoinette Strydom</p>
      <p id="to">AAN</p>
      <p className="inviteName hanro">Hanro Fourie</p>
      <p className="inviteBody">seun van Wietsche en Elaine Fourie.</p>
      <img src={AccentInvert} className="inviteAccent" alt="" />
    </div>
  );
} 