import Sonnekus from '../../images/Accommodation/Sonnekus.jpeg';
import SonnekusLogo from "../../images/Accommodation/SonnekusLogo.png";
import { IoIosStar, IoIosStarHalf } from "react-icons/io";
import { GoDash } from "react-icons/go";
import './Accommodation.css';

export default function Accommodation() {
  return (
    <div className='Accommodation' id='Accommodation'>
      <div id='accommodationBanner'>
        <img src={Sonnekus} alt='Sonnekus' />
      </div>
      <img src={SonnekusLogo} id='sonnekusLogo' alt='Sonnekus Logo' />
      <p id='starRating'>4.5 &nbsp;
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStarHalf />
      </p>
      <p id='accommodationDescription'>Located just 1.2km from Casa Labia, Sonnekus offers a luxurious and comfortable stay, boasting stunning ocean and mountain views. </p>
      <a href='https://www.sonnekus.co.za/' target='_blank' rel='noreferrer' id='bookingButton' >
        Visit Website
      </a>
      <h3>Nearby Activities</h3>
      <ul>
        <li>
          <GoDash />
          <div>
            Muizenberg Beach
            <p className='distance'>2.5km away</p>
          </div>
        </li>
        <li>
          <GoDash />
          <div>
            St. James Walkway
            <p className='distance'>350m away</p>
          </div>
        </li>
        <li>
          <GoDash />
          <div>
            Kalk Bay
            <p className='distance'>1.2km away</p>
          </div>
        </li>
        <li>
          <GoDash />
          <div>
            Winelands
            <p className='distance'>15km away</p>
          </div>
        </li>
        <li>
          <GoDash />
          <div>
            Simonstown
            <p className='distance'>10km away</p>
          </div>
        </li>
      </ul>
      <p id='accommodationDescription'>
        <strong>Please note</strong> that the wedding takes place during Muizenberg's peak tourist season. Therefore, please ensure that your accommodation is booked as soon as possible (preferably by the end of August).
      </p>
      <br />
      <h3>Other Options</h3>
      <div id='accommodationOptions'>
        <a href='https://www.lekkeslaap.co.za/accommodation/surferscorner-luxury-self-catering-apartments?ppc=AdWords_LS-AccommNames&gad_source=1&gclid=Cj0KCQjwj9-zBhDyARIsAERjds0O0a_4v1KrsnRYnvYdFOtf11wiBRXzmbELorRWWDkpejGbrPUTfscaAmmdEALw_wcB' target='_blank' rel='noreferrer' id='bookingButton' >
          Surfer's Corner
        </a>
        <a href='https://www.abingtonmanor.co.za/' target='_blank' rel='noreferrer' id='bookingButton' >
          Abington Manor
        </a>
      </div>
    </div>
  );
} 