import './RSVP.css';

export default function RSVP() {
  return (
    <div className='RSVP' id='RSVP'>
      <h1>RSVP</h1>
      <a className='rsvpButton' href='https://forms.gle/BWQTVQjvZ74NZpwP6' target='_blank' rel='noreferrer'>Klik hier om te RSVP</a>
      <p>Ongelukkig is die RSVP-sisteem slegs in Engels beskikbaar.</p>
    </div>
  );
}