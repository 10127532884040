import React, { useState } from 'react';
import { CNavbar, CContainer, CNavbarBrand, CNavbarToggler, CCollapse, CNavLink, CNavbarNav } from '@coreui/react';
// Universal Components
// import LockScreen from './components/LockScreen/LockScreen';
import RSVP from './components/RSVP/RSVP';
import ScreenSize from './components/ScreenSize/ScreenSize';
// Import English Components
import Landing from './components/Landing/Landing';
import Invite from "./components/Invite/Invite";
import OurStory from './components/OurStory/OurStory';
import AboutTheVenue from './components/AboutTheVenue/AboutTheVenue';
import Accommodation from './components/Accommodation/Accommodation';
import WeddingDetails from './components/WeddingDetails/WeddingDetails';
import Contact from './components/Contact/Contact';
// Import Afrikaans Components
import LandingAfrikaans from './components/Landing/LandingAfrikaans';
import Uitnodiging from "./components/Invite/Uitnodiging";
import OnsStorie from './components/OurStory/OnsStorie';
import OorDieVenue from './components/AboutTheVenue/OorDieVenue';
import Akkommodasie from './components/Accommodation/Akkommodasie';
import TroueBesonderhede from './components/WeddingDetails/TroueBesonderhede';
import RSVPAfr from "./components/RSVP/RSVPAfr";
import Kontak from './components/Contact/Kontak';
// Import Images
import Monogram from "./images/Header/monogram.png";
// Import CSS
import '@coreui/coreui/dist/css/coreui.min.css';
import './App.css';

function App() {

  // State variable for header visibility
  const [visible, setVisible] = useState(false)

  // State variable for website Language
  const [websiteLanguage, setWebsiteLanguage] = useState("English");

  const handleLanguageChange = (language) => {
    setWebsiteLanguage(language);
  };

  if (websiteLanguage === "English") {
    return (
      <div className="App">
        {/* <LockScreen /> */}
        <ScreenSize />
        <Landing />
        {/* Header */}
        <div className="Header">
          <CNavbar expand="lg" className="bg-body-tertiary">
            <CContainer fluid>
              <CNavbarBrand href="#">
                <img src={Monogram} alt="Our Monogram" />
              </CNavbarBrand>
              <span id="languageSelector">
                <button className='languageButton selectedLanguage' onClick={() => handleLanguageChange("English")}>English</button>
                &nbsp;|&nbsp;
                <button className='languageButton' onClick={() => handleLanguageChange("Afrikaans")}>Afrikaans</button>
              </span>
              <CNavbarToggler
                aria-label="Toggle navigation"
                aria-expanded={visible}
                onClick={() => setVisible(!visible)}
              />
              <CCollapse className="navbar-collapse" visible={visible}>
                <CNavbarNav as="nav">
                  <CNavLink href="#">Home</CNavLink>
                  <CNavLink href="#OurStory">Our Story</CNavLink>
                  <CNavLink href="#AboutTheVenue">About The Venue</CNavLink>
                  <CNavLink href="#Accommodation">Accommodation</CNavLink>
                  <CNavLink href="#WeddingDetails">Wedding Details</CNavLink>
                  <CNavLink href="#RSVP">RSVP</CNavLink>
                  <CNavLink href="#Contact">Contact Us</CNavLink>
                </CNavbarNav>
              </CCollapse>
            </CContainer>
          </CNavbar>

        </div>
        <Invite />
        <OurStory />
        <AboutTheVenue />
        <Accommodation />
        <WeddingDetails />
        <RSVP />
        <Contact />
        <p className='copyright'>Last Update: 26 June 2024 &copy; Tomas van Rensburg</p>
      </div>
    );
  }

  if (websiteLanguage === "Afrikaans") {
    return (
      <div className="App">
        {/* <LockScreen /> */}
        <ScreenSize />
        <LandingAfrikaans />
        {/* Header */}
        <div className="Header">
          <CNavbar expand="lg" className="bg-body-tertiary">
            <CContainer fluid>
              <CNavbarBrand href="#">
                <img src={Monogram} alt="Our Monogram" />
              </CNavbarBrand>
              <span id="languageSelector">
                <button className='languageButton' onClick={() => handleLanguageChange("English")}>English</button>
                &nbsp;|&nbsp;
                <button className='languageButton selectedLanguage' onClick={() => handleLanguageChange("Afrikaans")}>Afrikaans</button>
              </span>
              <CNavbarToggler
                aria-label="Toggle navigation"
                aria-expanded={visible}
                onClick={() => setVisible(!visible)}
              />
              <CCollapse className="navbar-collapse" visible={visible}>
                <CNavbarNav as="nav">
                  <CNavLink href="#">Tuisblad</CNavLink>
                  <CNavLink href="#OurStory">Ons Storie</CNavLink>
                  <CNavLink href="#AboutTheVenue">Oor die Venue</CNavLink>
                  <CNavLink href="#Accommodation">Akkommodasie</CNavLink>
                  <CNavLink href="#WeddingDetails">Troue Besonderhede</CNavLink>
                  <CNavLink href="#RSVP">RSVP</CNavLink>
                  <CNavLink href="#Contact">Kontak Ons</CNavLink>
                </CNavbarNav>
              </CCollapse>
            </CContainer>
          </CNavbar>

        </div>
        <Uitnodiging />
        <OnsStorie />
        <OorDieVenue />
        <Akkommodasie />
        <TroueBesonderhede />
        <RSVPAfr />
        <Kontak />
        <p className='copyright'>Last Update: 26 June 2024 &copy; Tomas van Rensburg</p>
      </div>
    );
  }
}

export default App;
