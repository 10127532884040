import VenuePhotoOne from "../../images/AboutTheVenue/VenueOne.png";
import VenuePhotoTwo from "../../images/AboutTheVenue/VenueTwo.png";
import VenuePhotoThree from "../../images/AboutTheVenue/VenueThree.png";
import CasaLabiaLogo from "../../images/AboutTheVenue/CasaLabiaLogo.png";
import './AboutTheVenue.css';

export default function AboutTheVenue() {
  return (
    <div className='AboutTheVenue' id="AboutTheVenue">
      <img src={VenuePhotoOne} className="VenuePhoto" alt="" />
      <img src={VenuePhotoTwo} className="VenuePhoto" alt="" />
      <img src={VenuePhotoThree} className="VenuePhoto" alt="" />
      <div className="heading">
        <h1 id="headingLeft">About</h1>
        <h1>The</h1>
        <h1 id="headingRight">Venue</h1>
      </div>
      <a href="https://www.casalabia.com/" target="_blank" rel="noreferrer">
        <img src={CasaLabiaLogo} id="venueLogo" alt="Casa Labia Logo" />
      </a>
      <p>Located on Muizenberg&#39;s main road, Casa Labia seamlessly combines historical grandeur with modern functionality. Originally serving as the Italian embassy in South Africa, it features exquisite imported Venetian interiors.</p>
      <br />
      <p>Built in 1929 by Count and Countess Labia, this national monument offers a unique blend of history and elegance, serving as the ideal canvas for the start of our shared journey.
      </p>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.3730317367267!2d18.463844576065323!3d-34.11119863165525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc41ae596d63db%3A0x24cbd92fd9f19c55!2sCasa%20Labia!5e0!3m2!1sen!2sza!4v1719212082769!5m2!1sen!2sza"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title='sonnekus'
      />
    </div>
  );
}