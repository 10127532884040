// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// Import CSS
import './OurStory.css';

// Importing photos
import Hands from "../../images/OurStory/icons/hands.png";
import Intertwine from "../../images/OurStory/icons/intertwine.png";
import Phone from "../../images/OurStory/icons/phone.png";
import Popcorn from "../../images/OurStory/icons/popcorn.png";
import Cap from "../../images/OurStory/icons/cap.png";
import Hearts from "../../images/OurStory/icons/hearts.png";
import Veg from "../../images/OurStory/icons/veg.png";
import Dating from "../../images/OurStory/icons/dating.png";
import Paws from "../../images/OurStory/icons/paws.png";
import Engaged from "../../images/OurStory/icons/engaged.png";
import Married from "../../images/OurStory/icons/married.png";


export default function OurStory() {
  return (
    <div className='OurStory' id='OurStory'>
      <h1>
        Our
        <span>Story</span>
      </h1>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide id='swiperSlideOne'>
          <div className='storyIcon'>
            <img src={Hands} alt='' />
            <h3>2016</h3>
          </div>
          <p>Antoinette and Hanro first meet, unknowingly marking the beginning of a connection that would much later blossom into a friendship worthy of matrimony.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideTwo'>
          <div className='storyIcon'>
            <img src={Intertwine} alt='' />
            <h3>2016-2021</h3>
          </div>
          <p>Their lives intertwine in subtle ways—innocent glances, accidental eye contact and brief conversations become a recurring theme against the background of their youthful student days.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideThree'>
          <div className='storyIcon'>
            <img src={Phone} alt='' />
            <h3>April 2022</h3>
          </div>
          <p>Hanro&#39;s flirtatious dance-moves from afar finally pay off and Antoinette swipes right.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideFour'>
          <div className='storyIcon'>
            <img src={Popcorn} alt='' />
            <h3>23 April 2022</h3>
          </div>
          <p>Antoinette joins Hanro for a night of theatre (Ster-Kinekor) and refreshments (beer), marking their first official date.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideFive'>
          <div className='storyIcon'>
            <img src={Cap} alt='' />
            <h3>24 April 2022</h3>
          </div>
          <p>Antoinette pretends to like Formula One to see Hanro the very next day.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideSix'>
          <div className='storyIcon'>
            <img src={Hearts} alt='' />
            <h3>4 May 2022</h3>
          </div>
          <p>Hanro and Antoinette share their first kiss.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideSeven'>
          <div className='storyIcon'>
            <img src={Veg} alt='' />
            <h3>5 May 2022</h3>
          </div>
          <p>Hanro pretends to like vegetable curry to see Antoinette the very next day.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideEight'>
          <div className='storyIcon'>
            <img src={Dating} alt='' />
            <h3>2 September 2022</h3>
          </div>
          <p>Hanro asks Antoinette to be his girlfriend.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideNine'>
          <div className='storyIcon'>
            <img src={Paws} alt='' />
            <h3>22 September 2022</h3>
          </div>
          <p>Resin brings Wölke into their family.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideTen'>
          <div className='storyIcon'>
            <img src={Engaged} alt='' />
            <h3>27 December 2023</h3>
          </div>
          <p>Hanro proposes to Antoinette.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideEleven'>
          <div className='storyIcon'>
            <img src={Married} alt='' />
            <h3>23 November 2024</h3>
          </div>
          <p>I DO!</p>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}