// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// Import CSS
import './OurStory.css';

// Importing photos
import Hands from "../../images/OurStory/icons/hands.png";
import Intertwine from "../../images/OurStory/icons/intertwine.png";
import Phone from "../../images/OurStory/icons/phone.png";
import Popcorn from "../../images/OurStory/icons/popcorn.png";
import Cap from "../../images/OurStory/icons/cap.png";
import Hearts from "../../images/OurStory/icons/hearts.png";
import Veg from "../../images/OurStory/icons/veg.png";
import Dating from "../../images/OurStory/icons/dating.png";
import Paws from "../../images/OurStory/icons/paws.png";
import Engaged from "../../images/OurStory/icons/engaged.png";
import Married from "../../images/OurStory/icons/married.png";


export default function OurStory() {
  return (
    <div className='OurStory' id='OurStory'>
      <h1>
        Ons
        <span>Storie</span>
      </h1>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide id='swiperSlideOne'>
          <div className='storyIcon'>
            <img src={Hands} alt='' />
            <h3>2016</h3>
          </div>
          <p>Antoinette en Hanro ontmoet, min wetende dat dit die begin van 'n vriendskap inlui wat heelwat later voor die kansel voltrek sal word.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideTwo'>
          <div className='storyIcon'>
            <img src={Intertwine} alt='' />
            <h3>2016-2021</h3>
          </div>
          <p>Hulle lewens verweef op subtiele maniere—onskuldige kykies, toevallige oogkontak en kort gesprekke word 'n herhalende tema teen die agtergrond van hul sorgvrye studentedae.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideThree'>
          <div className='storyIcon'>
            <img src={Phone} alt='' />
            <h3>April 2022</h3>
          </div>
          <p>Antoinette word uiteindelik deur Hanro se gewaagde danspassies beïndruk en sy 'swipe' regs.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideFour'>
          <div className='storyIcon'>
            <img src={Popcorn} alt='' />
            <h3>23 April 2022</h3>
          </div>
          <p>Antoinette sluit by Hanro aan vir 'n aand van Teater (Ster-Kinekor) en verversings (bier), wat hul eerste amptelike 'date' aandui.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideFive'>
          <div className='storyIcon'>
            <img src={Cap} alt='' />
            <h3>24 April 2022</h3>
          </div>
          <p>Antoinette maak asof sy van 'Formula One' hou om Hanro die volgende dag weer te sien.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideSix'>
          <div className='storyIcon'>
            <img src={Hearts} alt='' />
            <h3>4 May 2022</h3>
          </div>
          <p>Hanro en Antoinette deel hul eerste soen.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideSeven'>
          <div className='storyIcon'>
            <img src={Veg} alt='' />
            <h3>5 May 2022</h3>
          </div>
          <p>Hanro maak asof hy van groentekerrie hou om Antoinette die volgende dag weer te sien.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideEight'>
          <div className='storyIcon'>
            <img src={Dating} alt='' />
            <h3>2 September 2022</h3>
          </div>
          <p>Hanro vra Antoinette om sy meisie te wees.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideNine'>
          <div className='storyIcon'>
            <img src={Paws} alt='' />
            <h3>22 September 2022</h3>
          </div>
          <p>Resin bring Wölke in hul gesin in.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideTen'>
          <div className='storyIcon'>
            <img src={Engaged} alt='' />
            <h3>27 December 2023</h3>
          </div>
          <p>Hanro vra Antoinette om te trou.</p>
        </SwiperSlide>
        <SwiperSlide id='swiperSlideEleven'>
          <div className='storyIcon'>
            <img src={Married} alt='' />
            <h3>23 November 2024</h3>
          </div>
          <p>JA!</p>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}