import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import './Contact.css';

export default function Contact() {
  return (
    <div className='Contact' id="Contact">
      <h1>Kontak Ons</h1>
      <p>Indien daar enige vrae of bekommernisse is, kontak ons asseblief:</p>
      <div id="contactCard">
        <div id="contactLinks">
          <p className="contactName">Antoinette</p>
          <a href="tel:0726087660" className="contactLink">
            <FaPhoneAlt className="contactIcon" />
            &nbsp;
            +27 (72) 608-7660
          </a>
          <a href="https://wa.me/270726087660" className="contactLink">
            <FaWhatsapp className="contactIcon" />
            &nbsp;
            Whatsapp
          </a>
          <a href="mailto:antoinette1997@outlook.com" className="contactLink">
            <MdAlternateEmail className="contactIcon" />
            &nbsp;
            Email
          </a>
        </div>
        <div id="contactLinks">
          <p className="contactName">Hanro</p>

          <a href="tel:0847719184" className="contactLink">
            <FaPhoneAlt className="contactIcon" />
            &nbsp;
            +27 (84) 771-9184
          </a>
          <a href="https://wa.me/270847719184" className="contactLink">
            <FaWhatsapp className="contactIcon" />
            &nbsp;
            Whatsapp
          </a>
          <a href="mailto:hanro4e@gmail.com" className="contactLink">
            <MdAlternateEmail className="contactIcon" />
            &nbsp;
            Email
          </a>
        </div>
      </div>
    </div>
  );
} 