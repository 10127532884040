import Sonnekus from '../../images/Accommodation/SonnekusAfr.jpeg';
import SonnekusLogo from "../../images/Accommodation/SonnekusLogo.png";
import { IoIosStar, IoIosStarHalf } from "react-icons/io";
import { GoDash } from "react-icons/go";
import './Accommodation.css';

export default function Accommodation() {
  return (
    <div className='Accommodation' id='Accommodation'>
      <div id='accommodationBanner'>
        <img src={Sonnekus} alt='Sonnekus' />
      </div>
      <img src={SonnekusLogo} id='sonnekusLogo' alt='Sonnekus Logo' />
      <p id='starRating'>4.5 &nbsp;
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStar />
        <IoIosStarHalf />
      </p>
      <p id='accommodationDescription'>Sonnekus, slegs 1.2 km vanaf Casa Labia, bied luukse en gemaklike verblyf met asemrowende see- en berguitsigte.</p>
      <a href='https://www.sonnekus.co.za/' target='_blank' rel='noreferrer' id='bookingButton' >
        Besoek Webwerf
      </a>
      <h3>Nabygeleë Aktiwiteite</h3>
      <ul>
        <li>
          <GoDash />
          <div>
            Muizenberg Strand
            <p className='distance'>2.5km weg</p>
          </div>
        </li>
        <li>
          <GoDash />
          <div>
            St. James Staproete
            <p className='distance'>350m weg</p>
          </div>
        </li>
        <li>
          <GoDash />
          <div>
            Kalkbaai
            <p className='distance'>1.2km weg</p>
          </div>
        </li>
        <li>
          <GoDash />
          <div>
            Wynlande
            <p className='distance'>15km weg</p>
          </div>
        </li>
        <li>
          <GoDash />
          <div>
            Simonstad
            <p className='distance'>10km weg</p>
          </div>
        </li>
      </ul>
      <p id='accommodationDescription'>
        <strong>Let asseblief</strong> daarop dat die troue tydens Muizenberg se spitstoeristeseisoen plaasvind. Maak dus seker dat jul akkommodasie so vroeg moontlik bespreek word (verkieslik teen die einde van Augustus).
      </p>
      <br />
      <h3>Ander Opsies</h3>
      <div id='accommodationOptions'>
        <a href='https://www.lekkeslaap.co.za/accommodation/surferscorner-luxury-self-catering-apartments?ppc=AdWords_LS-AccommNames&gad_source=1&gclid=Cj0KCQjwj9-zBhDyARIsAERjds0O0a_4v1KrsnRYnvYdFOtf11wiBRXzmbELorRWWDkpejGbrPUTfscaAmmdEALw_wcB' target='_blank' rel='noreferrer' id='bookingButton' >
          Surfer's Corner
        </a>
        <a href='https://www.abingtonmanor.co.za/' target='_blank' rel='noreferrer' id='bookingButton' >
          Abington Manor
        </a>
      </div>
    </div>
  );
} 