import React, { useState } from "react";
import Accent from "../../images/WeddingDetails/accent.jpeg";
import lineBreak from "../../images/WeddingDetails/lineBreak.png";
import { MdClose } from "react-icons/md";
import './WeddingDetails.css';

export default function WeddingDetails() {
  // State Variables for controlling modals
  const [isSpecialOfferModalOpen, setIsSpecialOfferModalOpen] = useState(false);
  const [isHoneymoonFundModalOpen, setIsHoneymoonFundModalOpen] = useState(false);

  // Functions to open modals
  const handleSpecialOfferModalOpen = () => {
    setIsSpecialOfferModalOpen(true);
  };

  const handleHoneymoonFundModalOpen = () => {
    setIsHoneymoonFundModalOpen(true);
  };

  // Functions to close modals
  const handleSpecialOfferModalClose = () => {
    setIsSpecialOfferModalOpen(false);
  };

  const handleHoneymoonFundModalClose = () => {
    setIsHoneymoonFundModalOpen(false);
  };

  return (
    <div className='WeddingDetails' id="WeddingDetails">
      <div id='detailsHeading'>
        <h1 id='detailsHeadingOne'>Wedding</h1>
        <h1 id='detailsHeadingTwo'>Details</h1>
      </div>
      <img src={Accent} className="accent" alt='' />
      <h2>Weekend Schedule</h2>
      <div id="weddingSchedule">
        <div id="dayBefore">
          <br />
          <h4>THE DAY BEFORE</h4>
          <p>Please join us for a</p>
          <p>MEET AND GREET</p>
          <p>Friday, November 22, 2024</p>
          <p>16:30 to 19:30</p>
          <p>Cape to Cuba,</p>
          <p>165 Main Road, Kalk Bay</p>
          <br />
        </div>
        <img src={lineBreak} id="lineBreak" alt="" />
        <div>
          <h4>THE WEDDING</h4>
          <p>Saturday, November 23, 2024</p>
          <div >
            <p>14:45 - All guests to be seated for ceremony</p>
            <p>15:00 - Ceremony</p>
          </div>
          <p>Casa Labia by Ideas Cartel</p>
          <p>Reception to follow.</p>
        </div>
      </div>
      <img src={Accent} className="cashbarAccent" alt='' />
      <p id="cashbar">A cash bar will be available both Friday and Saturday night.</p>
      <img src={Accent} className="cashbarAccent" alt='' />
      <br />
      <br />
      <h2>Dress Code:</h2>
      <h2>Formal</h2>
      <br />
      <div id="dressCode">
        <div id="men">
          <p className="gender">MEN</p>
          <p>
            Suit and Necktie
            <br />
            - OR -
            <br />
            Tuxedo
          </p>
          <p>
            <br />
            DON'TS
            <MdClose id="dontCross" />
          </p>
          <div className="colours">
            <div className="colour colourOne"></div>
            <div className="colour colourTwo"></div>
            <div className="colour colourThree"></div>
            <div className="colour colourFour"></div>
            <div className="colour colourFive"></div>
          </div>
        </div>
        <img src={lineBreak} id="lineBreak" alt="" />
        <div id="women">
          <p className="gender">WOMEN</p>
          <p>
            Formal Dress
            <br />
            - OR -
            <br />
            Evening Gown
          </p>
          <p>
            <br />
            DON'TS
            <MdClose id="dontCross" />
          </p>
          <div className="colours">
            <div className="colour colourSix"></div>
            <div className="colour colourSeven"></div>
            <div className="colour colourEight"></div>
            <div className="colour colourNine"></div>
            <div className="colour colourTen"></div>
          </div>
        </div>
      </div>
      <p id="disclaimer">
        *DONT'S meaning any colours
        <strong> EXCEPT</strong> these
      </p>
      <button id="specialOffer" onClick={handleSpecialOfferModalOpen}>15% off Dresses from StyleRotate</button>
      <img src={Accent} className="accent dressCodeAccent" alt='' />
      <p id="dressCodeDescription">
        Please note;
        the reception will be outdoors and it can become quite chilly at night on the coast.
        <br />
        - We recommend bringing a shawl or light jacket.
      </p>
      <img src={Accent} className="accent dressCodeAccent" alt='' />

      {/* Special Offer Modal */}
      {isSpecialOfferModalOpen && (
        <div className="offerModal">
          <div className="offerModal-content">
            <span className="offerClose" onClick={handleSpecialOfferModalClose}>&times;</span>
            <p>We managed to obtain a 15% discount code for dress rentals from StyleRotate:</p>
            <p><strong>FOREVER4E</strong></p>
            <p>The codes will be live from 1 September and will expire at midnight on 24 November.</p>
            <p id="disclaimer">*Please note that you can book only three months in advance.</p>
            <a href="https://stylerotate.com/" target="_blank" rel="noreferrer" className="offerModalButton">Visit StyleRotate Website</a>
          </div>
        </div>
      )}

      <section id="gifts">
        <br />
        <br />
        <h1>Gifts</h1>
        <br />
        <p>Your presence at our wedding is the best gift we could receive. However, for those who feel they would like to contribute, we would be grateful for donations towards our honeymoon so we can make it a truly once-in-a-lifetime trip.</p>
        <button id="honeymoonFund" onClick={handleHoneymoonFundModalOpen}>Honeymoon Fund</button>
      </section>

      {/* Honeymoon Fund Modal */}
      {isHoneymoonFundModalOpen && (
        <div className="honeymoonModal">
          <div className="honeymoonModal-content">
            <span className="honeymoonClose" onClick={handleHoneymoonFundModalClose}>&times;</span>
            <p>To donate, please use the following banking details:</p>
            <p className="bankingDetails">A.Strydom
              <br />ABSA
              <br />Account Type: Current
              <br />Account number: 4098298522
              <br />Branch Code: 632005
              <br />Reference: Honeymoon Fund
            </p>
            <p>Ice Cream- R50
              <br />Roadtrip Snacks - R100
              <br />Cheese tasting- R150
              <br />Bottle of Wine - R250
              <br />Cocktails - R300
              <br />Pizza Dinner- R450
              <br />Wine Tasting - R500
              <br />Picnic - R650
              <br />Couples Massage - R800
              <br />Quad Bike - R1000
              <br />Tank of Petrol- R1200
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
